/* eslint-disable no-console */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        counters: {},
    },
    getters: {
        counters(state) {
            return state.counters
        },
    },
    mutations: {
        SET_COUNTERS(state, counters) {
            state.counters = counters
        },
    },
    actions: {
        async getCountersAction({ commit }) {
            await axios.get('counters').then(({ data }) => commit('SET_COUNTERS', data))
        },
    },
}
