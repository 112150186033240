/* eslint-disable no-console */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        contacts: [],
        pagination: {},
    },
    getters: {
        contacts(state) {
            return state.contacts
        },
        pagination(state) {
            return state.pagination
        },
    },
    mutations: {
        SET_CONTACTS(state, contacts) {
            state.contacts = contacts
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
    },
    actions: {
        async getContactsAction({ commit }, query = null) {
            let link = 'contacts'

            if (query) {
                link += `?${query}`
            }

            await axios.get(link).then(({ data }) => {
                commit('SET_PAGINATION', data.pagination)
                return commit('SET_CONTACTS', data.data)
            })
        },
        deleteContactAction({ dispatch }, id) {
            return axios.delete(`contact/${id}`).then(() => dispatch('getContactsAction'))
        },
    },
}
