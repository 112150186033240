/* eslint-disable */
import axios from 'axios'
import { appUrl } from '@/config/apiConfig'

export default {
    namespaced: true,
    state: {
        token: null,
        user: {},
        hunter: {},
        postmark: {},
    },
    getters: {
        authenticated(state) {
            return state.user && state.token
        },
        user(state) {
            return state.user
        },
        hunter(state) {
            return state.hunter
        },
        postmark(state) {
            return state.postmark
        },
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_HUNTER(state, hunter) {
            state.hunter = hunter
        },
        SET_POSTMARK(state, postmark) {
            state.postmark = postmark
        },
    },
    actions: {
        async signIn({ dispatch }, credentials) {
            const response = await axios.post(`${appUrl}oauth/token`, credentials)
            return dispatch('attempt', response.data.data.access_token)
        },
        async attempt({ dispatch, commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }

            if (!state.token) {
                return
            }

            try {
                const response = await axios.get('me')
                commit('SET_USER', response.data)
                dispatch('getHunterInfo')
                dispatch('getPostmarkInfo')
            } catch (error) {
                commit('SET_TOKEN', null)
                commit('SET_USER', {})
            }
        },
        async signOut({ commit }) {
            await axios.post(`${appUrl}oauth/signout`).then(() => {
                commit('SET_TOKEN', null)
                return commit('SET_USER', {})
            })
        },
        async getHunterInfo({ commit }) {
            await axios.get('hunter-info').then(({ data }) => {
                return commit('SET_HUNTER', data.data)
            })
        },
        async getPostmarkInfo({ commit }) {
            await axios.get('postmark-info').then(({ data }) => {
                return commit('SET_POSTMARK', data.data)
            })
        },
    },
}
