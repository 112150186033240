/* eslint-disable no-console */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        packages: [],
        home: {},
    },
    getters: {
        packages(state) {
            return state.packages
        },
        home(state) {
            return state.home
        },
    },
    mutations: {
        SET_PACKAGES(state, packages) {
            state.packages = packages
        },
        SET_HOME_DATA(state, home) {
            state.home = home
        },
    },
    actions: {
        async getPackagesAction({ commit }) {
            await axios.get('packages').then(({ data }) => commit('SET_PACKAGES', data.data))
        },
        async addPackageAction({ dispatch }, data) {
            await axios.post('package', data).then(() => dispatch('getPackagesAction'))
        },
        async updatePackageAction({ dispatch }, data) {
            await axios.put(`package/${data.id}`, data.data).then(() => dispatch('getPackagesAction'))
        },
        async deletePackageAction({ dispatch }, id) {
            await axios.delete(`package/${id}`).then(() => dispatch('getPackagesAction'))
        },
        //
        async changePasswordAction(_, data) {
            return axios.post('password', data)
        },
        //
        async getHomeAction({ commit }) {
            await axios.get('home').then(({ data }) => commit('SET_HOME_DATA', data.data))
        },
        async updateHomeAction({ dispatch }, data) {
            await axios.put('home', data).then(() => dispatch('getHomeAction'))
        },
        //
        async importCompaniesAction(_, data) {
            return axios.post('import-companies-data', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        async importEmployeesAction(_, data) {
            return axios.post('import-employees-data', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        async importIndustriesAction(_, data) {
            return axios.post('import-industries-data', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        async scrapeCrunchbaseData(_, data) {
            return axios.post('scrape-crunchbase-data', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        async scrapeCrunchbaseLink(_, data) {
            return axios.post('scrape-crunchbase-link', data)
        },
    },
}
