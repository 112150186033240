/* jshint esversion: 6 */
export const VERSION = 'admin'

// LOCAL
// export const appUrl = 'http://127.0.0.1:8000/'

// LIVE
export const appUrl = 'https://api.crmzz.com/'
export const dashUrl = 'https://crmzz.com/'

export const clientId = '90ea9d1d-d62b-4728-a07b-43940cb66130'
export const clientSecret = 'TvzFUQ8rO2jrXZjjgGmfHcqshLEBDW83pAQUPX9j'

// NOTE: Don't Change This
export const API_URL = `${appUrl}api/${VERSION}/`
