/* eslint-disable */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'

/* eslint-disable */
require('./subscriber');

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/clipboard'
import '@/libs/portal-vue'
import '@/libs/toastification'

import VueSweetalert2 from 'vue-sweetalert2'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueSweetalert2)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import { API_URL } from '@/config/apiConfig';
axios.defaults.baseURL = API_URL;
Vue.prototype.$http = axios;

const token = localStorage.getItem('admin-token');
if (token) {
	Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'

Vue.mixin({
	data: function () {
		return {
			mainPerPage: 100,
			paginationCurrentPage: 1,
			attribute: 'created_at',
			sort: 'desc',
			pageY: 0,
			pageX: 0,
			urlPrevImg: null,
		}
	},
	setup() {
		return {
			avatarText,
		}
	},
	methods: {
		showToast(message, type = 'success', icon = 'InfoIcon') {
			this.$toast({
				component: ToastificationContent,
				props: {
					title: message,
					icon: icon,
					variant: type,
				},
			})
		},
		previewPhoto(event, URL) {
			this.pageY = event.pageY + 0
			this.pageX = event.pageX + 0
			if (URL) {
				this.urlPrevImg = URL
			} else {
				this.urlPrevImg = null
			}
		},
		onCopy() {
			this.showToast('Copied Successfully', 'primary', 'BellIcon')
		},
		openExternalLink(link) {
			if (!/^(?:f|ht)tps?\:\/\//.test(link)) {
				link = `http://${link}`
			}
			window.open(link, '_blank')
		},
	},
})

Vue.directive('ghost-img', element => {
    element.onerror = () => {
        element.src = require('./assets/images/user-placeholder.png')
    }
})

store.dispatch('auth/attempt', token).then(() => {
	new Vue({
		router,
		store,
		render: h => h(App),
	}).$mount('#app')
})