/* eslint-disable no-console */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        customers: [],
        customer: {},
    },
    getters: {
        customers(state) {
            return state.customers
        },
        customer(state) {
            return state.customer
        },
    },
    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers
        },
        SET_CUSTOMER_INFO(state, customer) {
            state.customer = customer
        },
    },
    actions: {
        async getCustomersAction({ commit }, query = null) {
            let link = 'customers'

            if (query) {
                link += `?${query}`
            }

            await axios.get(link).then(({ data }) => commit('SET_CUSTOMERS', data.data))
        },
        async getcustomerInfoAction({ commit }, username) {
            await axios.get(`customer/${username}`).then(({ data }) => commit('SET_CUSTOMER_INFO', data.data))
        },
        async updatecustomerAction({ commit }, obj) {
            await axios.put(`customer/${obj.username}`, obj.data).then(({ data }) => commit('SET_CUSTOMER_INFO', data.data))
        },
        async deletecustomerAction({ dispatch }, username) {
            await axios.delete(`customer/${username}`).then(() => dispatch('getCustomersAction'))
        },
    },
}
