/* eslint-disable no-console */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        reviews: [],
        pagination: {},
    },
    getters: {
        reviews(state) {
            return state.reviews
        },
        pagination(state) {
            return state.pagination
        },
    },
    mutations: {
        SET_REVIEWS(state, reviews) {
            state.reviews = reviews
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
    },
    actions: {
        async getReviewsAction({ commit }, query = null) {
            let link = 'reviews'

            if (query) {
                link += `?${query}`
            }

            await axios.get(link).then(({ data }) => {
                commit('SET_PAGINATION', data.pagination)
                return commit('SET_REVIEWS', data.data)
            })
        },
        async deleteReviewAction({ dispatch }, reviewId) {
            await axios.delete(`review/${reviewId}`).then(({ data }) => {
                if (data.success) {
                    return dispatch('getReviewsAction')
                }
                return new Promise()
            })
        },
        deleteReviewsAction(_, data) {
            return axios.post('review/multi-delete', data) // .then(() => dispatch('getReviewsAction'))
        },
    },
}
