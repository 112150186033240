/* eslint-disable */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        companies: [],
        pagination: {},
        categories: [],
        company: {},
        selection: [],
    },
    getters: {
        companies(state) {
            return state.companies
        },
        categories(state) {
            return state.categories
        },
        company(state) {
            return state.company
        },
        pagination(state) {
            return state.pagination
        },
        selection(state) {
            return state.selection
        },
    },
    mutations: {
        SET_COMPANIES(state, companies) {
            state.companies = companies
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
        SET_COMPANY_CATEGORIES(state, categories) {
            state.categories = categories
        },
        SET_COMPANY_INFO(state, company) {
            state.company = company
        },
        SET_SELECTED_COMPANIES(state, selection) {
            state.selection = selection
        },
    },
    actions: {
        async getCompaniesAction({ commit }, query = null) {
            let link = 'companies'

            if (query) {
                link += `?${query}`
            }

            await axios.get(link).then(({ data }) => {
                commit('SET_SELECTED_COMPANIES', data.selected)
                commit('SET_PAGINATION', data.pagination)
                return commit('SET_COMPANIES', data.data)
            })
        },
        async getCompanyCategoriesAction({ commit }) {
            await axios.get('company-categories').then(({ data }) => commit('SET_COMPANY_CATEGORIES', data.data))
        },
        async getCompanyDetailsAction({ commit }, slug) {
            await axios.get(`company/${slug}`).then(({ data }) => commit('SET_COMPANY_INFO', data.data))
        },
        async disconnectCompanyRepresentativeAction({ dispatch }, data) {
            await axios.delete(`company/representative/${data.id}`).then(() => dispatch('getCompanyDetailsAction', data.slug))
        },
        async updateCompanyAction({ commit }, obj) {
            await axios.put(`company/${obj.slug}`, obj.data).then(({ data }) => commit('SET_COMPANY_INFO', data.data))
        },
        async deleteCompanyAction({ dispatch }, slug) {
            await axios.delete(`company/${slug}`).then(() => dispatch('getCompaniesAction'))
        },
        async companySearchAction({ commit }, obj) {
            await axios.get(`company/search/${obj.key || 'name'}/${obj.value}`).then(({ data }) => {
                commit('SET_PAGINATION', data.pagination)
                return commit('SET_COMPANIES', data.data)
            })
        },
        async setCompaniesForSearchPage(_, ids) {
            return axios.post('company/set-search-companies', { ids })
        },
        //
        async sendCompanyInvitationAction(_, data) {
            return axios.post('company-invitation', data)
        },
        async syncCompaniesAction({ state, dispatch }, data) {
            axios.post('sync-companies', data).then(() => {
                dispatch('auth/getHunterInfo', null, { root: true })
                if (data.hasSearch) {
                    return dispatch('companySearchAction', { key: 'name', value: data.searchQuery })
                } else {
                    // TODO: Make [attribute, sort] Dynamically
                    return dispatch('getCompaniesAction', `per_page=${state.pagination.perPage}&current_page=${state.pagination.page}&attribute=created_at&sort=desc`)
                }
            })
        },
    },
}
