import Vue from 'vue'
import Vuex from 'vuex'

// M O D U L E S : : :::
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import AuthModule from './modules/auth'
import reviewsModule from './modules/reviews'
import companiesModule from './modules/companies'
import foundersModule from './modules/founders'
import customersModule from './modules/customers'
import settingsModule from './modules/settings'
import contactsModule from './modules/contacts'
import statisticsModule from './modules/statistics'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production';
// strict: process.env.DEV,

export default new Vuex.Store({
    status: {
        //
    },
    actions: {
        //
    },
    mutations: {
        //
    },
    getters: {
        //
    },
    modules: {
        app,
        appConfig,
        verticalMenu,
        // NEW
        auth: AuthModule,
        reviews: reviewsModule,
        companies: companiesModule,
        founders: foundersModule,
        customers: customersModule,
        settings: settingsModule,
        contacts: contactsModule,
        statistics: statisticsModule,
    },
    // strict: debug
})
