import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/error-404',
			name: 'error-404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '*',
			redirect: 'error-404',
		},
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/analytics/index.vue'),
			// component: () => import('@/views/Home.vue'),
			meta: {
				requiresAuth: true,
				pageTitle: 'Home',
				breadcrumb: [{
					text: 'Home',
					active: true,
				}],
			},
		},
		{
			path: '/companies',
			name: 'companies',
			component: () => import('@/views/Companies.vue'),
			meta: {
				requiresAuth: true,
				pageTitle: 'Companies',
				breadcrumb: [{
					text: 'Companies',
					active: true,
				}],
			},
		},
		{
			path: '/company/:slug',
			name: 'company-info',
			component: () => import('@/views/CompanyInfo.vue'),
			props: true,
			meta: {
				requiresAuth: true,
				pageTitle: 'Company',
				breadcrumb: [{
					text: 'Companies',
					to: '/companies',
					active: false,
				},
				{
					text: 'Company',
					active: true,
				}],
			},
		},
		{
			path: '/edit-company/:slug',
			name: 'edit-company',
			component: () => import('@/views/EditCompany.vue'),
			props: true,
			meta: {
				requiresAuth: true,
				pageTitle: 'Company',
				breadcrumb: [{
					text: 'Companies',
					to: '/companies',
					active: false,
				},
				{
					text: ':slug',
					active: true,
				}],
			},
		},
		{
			path: '/founders',
			name: 'founders',
			component: () => import('@/views/Founders.vue'),
			meta: {
				requiresAuth: true,
				pageTitle: 'Founders',
				breadcrumb: [{
					text: 'Founders',
					active: true,
				}],
			},
		},
		{
			path: '/founder/:username',
			name: 'founder-info',
			// component: () => import('@/views/UserInfo.vue'),
			component: () => import('@/views/user-profile/Profile.vue'),
			props: true,
			meta: {
				requiresAuth: true,
				pageTitle: 'Founder',
				breadcrumb: [{
					text: 'Founders',
					to: '/founders',
					active: false,
				},
				{
					text: 'Founder',
					active: true,
				}],
			},
		},
		{
			path: '/edit-founder/:username',
			name: 'edit-founder',
			component: () => import('@/views/EditFounder.vue'),
			props: true,
			meta: {
				requiresAuth: true,
				pageTitle: 'Founder',
				breadcrumb: [{
					text: 'Founders',
					to: '/founders',
					active: false,
				},
				{
					text: ':username',
					active: true,
				}],
			},
		},
		{
			path: '/reviews',
			name: 'reviews',
			component: () => import('@/views/Reviews.vue'),
			meta: {
				requiresAuth: true,
				pageTitle: 'Reviews',
				breadcrumb: [{
					text: 'Reviews',
					active: true,
				}],
			},
		},
		{
			path: '/contacts',
			name: 'contacts',
			component: () => import('@/views/email/Email.vue'),
			meta: {
				contentRenderer: 'sidebar-left',
				contentClass: 'email-application',
				requiresAuth: true,
				// pageTitle: 'Contacts',
				// breadcrumb: [{
				// 	text: 'Contacts',
				// 	active: true,
				// }],
			},
		},
		{
			path: '/import-data',
			name: 'import-data',
			component: () => import('@/views/settings/ImportData.vue'),
			props: true,
			meta: {
				requiresAuth: true,
				pageTitle: 'Import Data',
				breadcrumb: [{
					text: 'Settings',
					to: '/settings',
					active: false,
				},
				{
					text: 'Import Data',
					active: true,
				}],
			},
		},
		{
			path: '/second-page',
			name: 'second-page',
			component: () => import('@/views/SecondPage.vue'),
			meta: {
				requiresAuth: true,
				pageTitle: 'Second Page',
				breadcrumb: [{
					text: 'Second Page',
					active: true,
				}],
			},
		},
	],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!localStorage.getItem('admin-token')) {
			router.replace({
				name: 'login',
			})
		} else {
			next()
		}
	} else {
		next() // make sure to always call next()!
	}
})

export default router
