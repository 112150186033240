/* eslint-disable */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        lists: [],
        founders: [],
        contacts: [],
        founder: {},
        pagination: {},
    },
    getters: {
        founders(state) {
            return state.founders
        },
        founder(state) {
            return state.founder
        },
        pagination(state) {
            return state.pagination
        },
        lists(state) {
            return state.lists
        },
        contacts(state) {
            return state.contacts
        },
    },
    mutations: {
        SET_FOUNDERS(state, founders) {
            state.founders = founders
        },
        SET_FOUNDER_INFO(state, founder) {
            state.founder = founder
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination
        },
        SET_FOUNDER_CONTACT_LISTS(state, lists) {
            state.lists = lists
        },
        SET_FOUNDER_CONTACTS(state, contacts) {
            state.contacts = contacts
        },
    },
    actions: {
        async getFoundersAction({ commit }, query = null) {
            let link = 'founders'

            if (query) {
                link += `?${query}`
            }

            await axios.get(link).then(({ data }) => {
                commit('SET_PAGINATION', data.pagination)
                return commit('SET_FOUNDERS', data.data)
            })
        },
        async addFounderAction(_, obj) {
            return axios.post('founder/new-founder', obj)
        },
        async deleteFoundersAction({ dispatch }, obj) {
            await axios.post('delete-founders', obj).then(() => dispatch('getFoundersAction'))
        },
        async sendFounderConfirmations(_, obj) {
            return axios.post('send-confirmation', obj)
        },
        async sendFounderInvitations({ dispatch }, obj) {
            await axios.post('send-invitation', obj).then(() => {
                return dispatch('auth/getPostmarkInfo', null, { root: true })
            })
        },
        async getFounderInfoAction({ commit }, username) {
            await axios.get(`founder/${username}`).then(({ data }) => commit('SET_FOUNDER_INFO', data.data))
        },
        async updateFounderAction({ commit }, obj) {
            await axios.put(`founder/${obj.username}`, obj.data).then(({ data }) => commit('SET_FOUNDER_INFO', data.data))
        },
        async deleteFounderAction({ dispatch }, username) {
            await axios.delete(`founder/${username}`).then(() => dispatch('getFoundersAction'))
        },
        async founderSearchAction({ commit }, obj) {
            await axios.get(`founder/search/${obj.key || 'name'}/${obj.value}`).then(({ data }) => {
                commit('SET_PAGINATION', data.pagination)
                return commit('SET_FOUNDERS', data.data)
            })
        },
        async founderContactListsAction({ commit }, obj) {
            await axios.get(`founder/contact-lists/${obj.username}/${obj.id}`).then(({ data }) => {
                return commit('SET_FOUNDER_CONTACT_LISTS', data.data)
            })
        },
        async founderContactsAction({ commit }, obj) {
            await axios.get(`founder/list-contacts/${obj.username}/${obj.id}?current_page=${obj.page || 1}&per_page=${obj.perPage || 20}`).then(({ data }) => {
                commit('SET_PAGINATION', data.pagination)
                return commit('SET_FOUNDER_CONTACTS', data.data)
            })
        },
        async syncFoundersAction({ dispatch, state }, obj) {
            return await axios.post('sync-founders', obj).then(({ data }) => {
                if (data.success) {
                    if (obj.hasSearch) {
                        dispatch('founderSearchAction', { key: 'name', value: obj.searchQuery })
                    } else {
                        // TODO: Make [attribute, sort] Dynamically
                        dispatch('getFoundersAction', `per_page=${state.pagination.perPage}&current_page=${state.pagination.page}&attribute=created_at&sort=desc`)
                    }

                    return new Promise((resolve, reject) => {
                        return resolve(data)
                    })
                } else {
                    return new Promise((resolve, reject) => {
                        return resolve(data)
                    })
                }
            })
        },
        async getTrackingEmailInfoAction(_, id) {
            return await axios.get(`email-info/${id}`)
        }
    },
}
